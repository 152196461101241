/* global algoliasearch instantsearch */

const searchClient = algoliasearch(
  'C6YE74WVBB',
  '83875ecedcf00a4b24f25250b4fecea4'
);

const search = instantsearch({
  indexName: 'docs',
  searchClient,
});

search.addWidgets([

  instantsearch.widgets.configure({
    hitsPerPage: 100
  }),

  instantsearch.widgets.searchBox({
    container: '#searchbox',
  }),
  instantsearch.widgets.stats({
    container: '#stats',
  }),
  instantsearch.widgets.clearRefinements({
    container: '#clear-refinements',
  }),
  instantsearch.widgets.refinementList({
    container: '#year-list',
    sortBy: ["name:desc"],
    attribute: 'year',
  }),
  instantsearch.widgets.refinementList({
    container: '#committee-list',
    sortBy: ["name:desc"],
    attribute: 'committee',
  }),
  instantsearch.widgets.sortBy({
    container: '#sort-by',
    items: [
      // { label: 'Featured', value: 'instant_search' },
      { value: 'docs', label: 'Most relevant' },
      { value: 'docs_date_desc', label: 'Most recent' },
      { value: 'docs_date_asc', label: 'Olderest' },

    ],
  }),

  instantsearch.widgets.hits({
    container: '#hits',
    templates: {
      item: `
      <div class="hit-price">{{objectID}}</div>
      {{#helpers.highlight}}{ "attribute": "content" }{{/helpers.highlight}}
      `,
    },
  }),
  instantsearch.widgets.pagination({
    container: '#pagination',
  }),
]);

search.start();
